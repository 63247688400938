import { Footer } from './common/footer.js';

export {
  Footer
}

export function getFilters(appliedQueryFilters) {

  let activeFilters = [];

  // Grabs NLP filters from appliedQueryFilter object passed through in transformData()
  if (appliedQueryFilters) {
    for (let [_, filter] of Object.entries(appliedQueryFilters)) {
      if (filter.value && !activeFilters.includes(filter.value)) {
        activeFilters.push(filter.value);
      }
    }
  }

  // Grabs active facet filters from answers core object
  let storage = ANSWERS.core.storage?.storage;
  if (!storage) return activeFilters;

  storage = Object.fromEntries(storage);
  if (!storage.facetFilters) return activeFilters;

  // Unpacking filter nested json structure
  for (let [_, i] of Object.entries(storage.facetFilters)) {
    if (i.length) {
      for (let [_, j] of Object.entries(i)) {
        for (let [_, filter] of Object.entries(j)) {
          if (filter['$eq'] && !activeFilters.includes(filter['$eq'])) {
            activeFilters.push(filter['$eq']);
          }
        }
      }
    }
  }

  return activeFilters;
}
